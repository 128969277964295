import React from 'react';

import {TextInputGroup} from '../../../components/inputs/TextInput';
import {IBessPowerMessage} from '../../../livedata/LiveDataModels';
import {FormProvider} from '../../../utils/FormContext';
import {T} from '../../../utils/Internationalization';

type LiveSectionProps = {
  liveData: IBessPowerMessage | undefined;
  data: {
    capacity: number;
    mode: string;
    stateOfHealth: number;
    cycleCount: number;
    stateOfCharge: number;
    batteryPower: number;
    gridPower: number;
    productionPower: number;
  };
  onChange: (field: keyof LiveSectionProps['data'], value: any) => void;
};

export default function LiveSection(props: LiveSectionProps) {
  const {liveData, data, onChange} = props;

  const handleInputChange = (field: keyof LiveSectionProps['data'], value: any) => {
    onChange(field, value);
  };

  // Styles
  const labelStyle = 'tw-font-bold tw-text-base tw-mb-0';
  const fieldStyle = 'tw-flex tw-flex-col tw-mb-4';

  return (
    <div>
      <FormProvider>
        <div className="tw-grid tw-grid-cols-1 lg:tw-grid-cols-2 lg:tw-gap-8">
          <div>
            <TextInputGroup
              name="capacity"
              label={T('bess.property.capacity')}
              className={fieldStyle}
              value={data.capacity.toString()}
              readOnly
              suffix="kWh"
              onChange={() => {}}
            />
            <TextInputGroup
              name="mode"
              label={T('bess.property.mode')}
              className={fieldStyle}
              value={data.mode}
              readOnly
              onChange={() => {}}
            />
            <TextInputGroup
              name="stateOfHealth"
              label={T('bess.property.stateOfHealth')}
              className={fieldStyle}
              value={data.stateOfHealth.toString()}
              readOnly
              suffix="%"
              onChange={() => {}}
            />
            <TextInputGroup
              name="cycleCount"
              label={T('bess.property.cycleCount')}
              className={fieldStyle}
              value={data.cycleCount.toString()}
              readOnly
              suffix="cycles"
              onChange={() => {}}
            />
            <TextInputGroup
              name="stateOfCharge"
              label={T('bess.property.stateOfCharge')}
              className={fieldStyle}
              value={liveData === undefined ? '--' : liveData.stateOfCharge.toFixed(2)}
              readOnly
              suffix="%"
              onChange={() => {}}
            />
            <TextInputGroup
              name="batteryPower"
              label={T('bess.property.batteryPower')}
              className={fieldStyle}
              value={liveData === undefined ? '--' : (liveData.batteryPower / 1000).toFixed(1)}
              readOnly
              suffix="kW"
              onChange={() => {}}
            />
            <TextInputGroup
              name="gridPower"
              label={T('bess.property.gridPower')}
              className={fieldStyle}
              value={liveData === undefined ? '--' : (liveData.gridPower / 1000).toFixed(1)}
              readOnly
              suffix="kW"
              onChange={() => {}}
            />
            <TextInputGroup
              name="productionPower"
              label={T('bess.property.produtionPower')}
              className={fieldStyle}
              value={liveData === undefined ? '--' : (liveData.solarAcPower / 1000).toFixed(1)}
              readOnly
              suffix="kW"
              onChange={() => {}}
            />
          </div>
        </div>
      </FormProvider>
    </div>
  );
}
