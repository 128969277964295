import {ILocationSummary} from '../../../models/Location';
import {IOverloadProtectionConfigMultiGrid} from '../../../models/OverloadProtection';
import {ISmartDevice} from '../../../models/SmartDevice';
import {
  bessCapacity,
  bessMaxChargeSpeed,
  bessMaxDischargeSpeed,
  bessMaxStateOfCharge,
  bessMinStateOfCharge,
  BessRate,
  BessSpecificationData,
  getSmartDeviceConfigurationPropertyValue
} from '../models/BessUnitConfiguration.model';

// Load Specification data
export function getSpecificationData(
  bessDevice: ISmartDevice,
  locationSummary: ILocationSummary,
  maximumLoadConfiguration: IOverloadProtectionConfigMultiGrid,
  capacityMaximumPowerValue: number
): BessSpecificationData {
  const serialNumber = locationSummary?.serialNumber;
  const latitude = locationSummary?.latitude;
  const longitude = locationSummary?.longitude;
  const maxCapacity = getSmartDeviceConfigurationPropertyValue(bessDevice, bessCapacity)?.BigDecimal;
  const maxDischargeSpeed = getSmartDeviceConfigurationPropertyValue(bessDevice, bessMaxDischargeSpeed)?.BigDecimal;
  const maxChargeSpeed = getSmartDeviceConfigurationPropertyValue(bessDevice, bessMaxChargeSpeed)?.BigDecimal;
  const maxStateOfCharge = getSmartDeviceConfigurationPropertyValue(bessDevice, bessMaxStateOfCharge)?.BigDecimal;
  const upperHealthStatus = getSmartDeviceConfigurationPropertyValue(bessDevice, bessMaxStateOfCharge)?.BigDecimal;
  const lowerHealthStatus = getSmartDeviceConfigurationPropertyValue(bessDevice, bessMinStateOfCharge)?.BigDecimal;
  const maxCurrent = maximumLoadConfiguration?.maximumLoad;
  const capacityMaximumPower = capacityMaximumPowerValue;
  const maxPeakPower = maximumLoadConfiguration?.maximumLoad
    ? (maximumLoadConfiguration?.maximumLoad * 3 * 230) / 1000
    : 0;
  const minStateOfCharge = getSmartDeviceConfigurationPropertyValue(bessDevice, bessMinStateOfCharge)?.BigDecimal;
  const gridFee = 0;
  const rate = BessRate.FixedRate;
  const customRate = 0;
  const fixedTariff = 0.1;
  const multiplicator = 2;
  const taxMultiplicator = 0;

  const specificationData: BessSpecificationData = {
    serialNumber,
    geographicLocation: {
      latitude,
      longitude
    },
    maxCapacity,
    maxDischargeSpeed,
    maxChargeSpeed,
    maxStateOfCharge,
    healthLimits: {
      lowerHealthStatus,
      upperHealthStatus
    },
    maxCurrent,
    maxPeakPower,
    capacityMaximumPower,
    minStateOfCharge,
    gridFee,
    rate,
    customRate,
    fixedTariff,
    multiplicator,
    taxMultiplicator
  };
  return specificationData;
}
