import {useCallback, useMemo} from 'react';

import {OrganizationInput, useQueryableOrganizations} from '../../components/inputs/OrganizationInput';
import PeriodSelector, {
  PeriodSettings,
  Period,
  PeriodRoundingMode,
  usePeriodRangeForTimezone
} from '../../components/PeriodSelector';
import Table, {IPersistedTableSettings, SortOrder} from '../../components/Table';
import {Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue} from '../../components/ui/select';
import {useModals} from '../../modals/ModalContext';
import {UserRights} from '../../models/AuthUser';
import {ICardSettings} from '../../models/CardSettings';
import {ChargingStationPaymentType, IChargingSession} from '../../models/ChargingStation';
import {IOrganization} from '../../models/Organization';
import {Interval} from '../../models/UsageValue';
import {hasPartnerFunctionality} from '../../models/User';
import {AppFeature, hasFeature} from '../../utils/AppParameters';
import {None} from '../../utils/Arrays';
import {useOrganization} from '../../utils/FunctionalData';
import {useAutoRefresh, useCardLoader} from '../../utils/Hooks';
import {plural, T} from '../../utils/Internationalization';
import {getBrowserTimezone} from '../../utils/TimestampUtils';
import {ICardType, CardCategory, CardTypeKey, CardLocationAwareness, ICardProps} from '../CardType';
import {useCardColumnSettings, useUser} from '../CardUtils';
import {getTableColumns} from '../ChargingStationTransactions/Columns';
import {FixSessionModal} from '../ChargingStationTransactions/FixSessionModal';
import {CardActions} from '../components';
import {ExportCsv, Reload} from '../components/actions';
import {CardView, cardViewProps, CustomActions} from '../components/CardView';

interface IChargingTransactionsSettings extends ICardSettings {
  table: IPersistedTableSettings;
  period: PeriodSettings;
  organizationId?: number;
  paymentType?: ChargingStationPaymentType;
}

const rowKey = (session: IChargingSession) => session.uuid;

const ChargingTransactions = (props: ICardProps<IChargingTransactionsSettings>) => {
  const {fetch, settings, updateSettings} = props;
  const {paymentType} = settings;
  const modals = useModals();

  const isServiceDesk = useUser().isServiceDesk();

  const [inputOrganizations, updateOrganizationInputQuery] = useQueryableOrganizations();
  const organizationId = settings.organizationId || inputOrganizations.defaultOrganization?.id;
  const [organization = inputOrganizations.defaultOrganization] = useOrganization(fetch, organizationId);

  const activePeriod = usePeriodRangeForTimezone(settings.period, getBrowserTimezone(), PeriodRoundingMode.INCLUSIVE);

  const [transactions, refreshTransactions] = useCardLoader(
    api => {
      if (organization === undefined || activePeriod === undefined) {
        return Promise.resolve(None);
      }

      return api.organizations.getCPOChargingSessions(organization.id, activePeriod.from, activePeriod.to);
    },
    [organization?.id, activePeriod?.from, activePeriod?.to],
    plural('chargingSession'),
    None
  );

  const refresh = refreshTransactions;
  useAutoRefresh(refresh);

  const columns = useMemo(() => {
    const handleFixSession = (session: IChargingSession) => {
      modals
        .show(props => <FixSessionModal session={session} {...props} />)
        .then(success => {
          if (success) {
            refreshTransactions();
          }
        });
    };
    return getTableColumns(None, true, isServiceDesk, handleFixSession);
  }, [isServiceDesk, modals, refreshTransactions]);

  const filteredTransactions = useMemo(() => {
    let result = transactions;
    if (paymentType) {
      result = result.filter(x => x.paymentType === paymentType);
    }
    return result;
  }, [transactions, paymentType]);

  const customSettings = useCardColumnSettings(columns);

  const handleChangePaymentType = useCallback(
    (type: string) => {
      const paymentType = type === ChargingStationPaymentType.Any ? undefined : (type as ChargingStationPaymentType);
      updateSettings({paymentType});
    },
    [updateSettings]
  );

  const handleChangeOrganization = useCallback(
    (org: IOrganization | undefined) => {
      updateSettings({organizationId: org ? org.id : undefined});
    },
    [updateSettings]
  );

  const actions: CustomActions = state => (
    <CardActions>
      {inputOrganizations.showInput && (
        <OrganizationInput
          name="organization"
          organizations={inputOrganizations.organizations}
          value={organization}
          onChange={handleChangeOrganization}
          onUpdateQuery={updateOrganizationInputQuery}
        />
      )}
      <Reload onReload={refresh} />
      {state.ready && <ExportCsv fields={columns} settings={settings.table} items={filteredTransactions} />}
      <PeriodSelector
        settings={settings.period}
        onChanged={period => updateSettings({period})}
        withoutInterval={true}
      />
      <Select
        name="payment-method"
        value={settings.paymentType || ''}
        defaultValue={ChargingStationPaymentType.Any}
        onValueChange={handleChangePaymentType}
      >
        <SelectTrigger className="tw-pl-1 !tw-text-base !tw-max-w-56">
          <SelectValue placeholder={T('paymentMethod.any')} />
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            <SelectItem value={ChargingStationPaymentType.Any}>{T('paymentMethod.any')}</SelectItem>
            <SelectItem value={ChargingStationPaymentType.Free}>{T('paymentMethod.free')}</SelectItem>
            <SelectItem value={ChargingStationPaymentType.App}>{T('paymentMethod.app')}</SelectItem>
            <SelectItem value={ChargingStationPaymentType.Whitelist}>{T('paymentMethod.whitelist')}</SelectItem>
            <SelectItem value={ChargingStationPaymentType.RFID}>{T('paymentMethod.rfid')}</SelectItem>
            <SelectItem value={ChargingStationPaymentType.SplitBilling}>{T('paymentMethod.splitBilling')}</SelectItem>
            <SelectItem value={ChargingStationPaymentType.PaymentTerminal}>
              {T('paymentMethod.paymentTerminal')}
            </SelectItem>
          </SelectGroup>
        </SelectContent>
      </Select>
    </CardActions>
  );

  let emptyMessage = T('chargingTransactions.none');
  if (organizationId === undefined) {
    emptyMessage = T('card.error.noOrganizationSelected');
  } else if (organization === undefined) {
    emptyMessage = T('card.error.organizationNotFound');
  }

  return (
    <CardView customSettings={customSettings} actions={actions} {...cardViewProps(props)}>
      <Table
        style={{flexGrow: 1}}
        items={filteredTransactions}
        fields={columns}
        rowKey={rowKey}
        settings={settings.table}
        updateSettings={table => updateSettings({table})}
        noun="chargingSession"
        emptyMessage={emptyMessage}
      />
    </CardView>
  );
};

const defaultTableSettings: IPersistedTableSettings = {
  pageSize: 20,
  sortColumn: 'from',
  sortOrder: SortOrder.DESCENDING,
  columns: [
    {name: 'stationSerial', visible: false},
    {name: 'stationName', visible: true},
    {name: 'from', visible: true},
    {name: 'to', visible: true},
    {name: 'duration', visible: true},
    {name: 'energy', visible: true},
    {name: 'paymentType', visible: true},
    {name: 'hostCost', visible: true},
    {name: 'commissionFee', visible: true},
    {name: 'emspFee', visible: true},
    {name: 'side', visible: true},
    {name: 'rfid', visible: true},
    ...(hasFeature(AppFeature.SocialLogin) ? [] : [{name: 'user', visible: true}])
  ]
};

const CARD: ICardType<IChargingTransactionsSettings> = {
  title: 'cpoChargingSessions.title',
  description: 'cpoChargingSessions.description',
  categories: [CardCategory.EV],
  rights: UserRights.User,
  type: CardTypeKey.CPOChargingSessions,
  isAvailable: hasPartnerFunctionality,
  cardClass: ChargingTransactions,
  width: 4,
  height: 2,
  locationAware: CardLocationAwareness.Unaware,
  defaultSettings: {
    table: defaultTableSettings,
    period: {
      interval: Interval.DAY, // unused
      period: Period.DAYS_14
    }
  }
};
export default CARD;
