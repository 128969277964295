import {ISmartDevice} from '../../../models/SmartDevice';
import {
  bessMaxStateOfCharge,
  bessMaxSurplusReserveStateOfCharge,
  bessMinPeakShavingStateOfCharge,
  bessMinStateOfCharge,
  bessModi,
  getSmartDeviceConfigurationPropertyValue
} from '../models/BessUnitConfiguration.model';

export function getModesAndTreshold(bessDevice: ISmartDevice) {
  const bessModes = getSmartDeviceConfigurationPropertyValue(bessDevice, bessModi);
  const maxSurplusReserveStateOfCharge = getSmartDeviceConfigurationPropertyValue(
    bessDevice,
    bessMaxSurplusReserveStateOfCharge
  )?.BigDecimal;
  const minPeakShavingStateOfCharge = getSmartDeviceConfigurationPropertyValue(
    bessDevice,
    bessMinPeakShavingStateOfCharge
  )?.BigDecimal;

  const usageConfigurationData = {
    modes: bessModes,
    treshold: {
      upperHealthStatus: getSmartDeviceConfigurationPropertyValue(bessDevice, bessMaxStateOfCharge)?.BigDecimal,
      lowerHealthStatus: getSmartDeviceConfigurationPropertyValue(bessDevice, bessMinStateOfCharge)?.BigDecimal,
      surplusReserve: maxSurplusReserveStateOfCharge,
      peakShavingSafety: minPeakShavingStateOfCharge
    }
  };
  return usageConfigurationData;
}
